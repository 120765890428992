import grid from "loop4-js-grid";
import config from "loop4-js-grid/src/default-config";

config.gutters = {
  0: "0",
  1: "0.5em",
  2: "1em",
  3: "2.5em",
};

config.margins = {
  0: "0",
  "1\\/2": "0.25em",
  1: "0.5em",
  2: "1em",
  "3\\/2": "1.25em",
  3: "2.5em",
  auto: "auto",
};

config.paddings = {
  0: "0",
  "1\\/2": "0.25em",
  1: "0.5em",
  2: "1em",
  "3\\/2": "1.25em",
  3: "2.5em",
  auto: "auto",
};

grid(config);
